import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withEmployee(Component) {
	return (props) => {

		const employee = useSelector(state => state.myEmployees.employee)
		const loadingStatus = useSelector(state => state.myEmployees.employeeStatus)
		const updateLoadingStatus = useSelector(state => state.myEmployees.employeeUpdateStatus)
		const dispatch = useDispatch()
		const employeeSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			employee,
			loadingStatus,
			updateLoadingStatus
		}
		const passThruProps = {...props, employeeSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withEmployee