import { createAsyncThunk } from '@reduxjs/toolkit'
import constants from '../redux/constants'

const api = client => ({
  create: (data) => {
    return async dispatch => {
      const createEmployee = createAsyncThunk(
        constants.CREATE_EMPLOYEE,
        async () => {
          const response = await client.post('/api/v2/my-ewa-employees', data)
          return response.data
        }
      )
      dispatch(createEmployee())
    }
  },
  findAll: (offset, limit, search = '', activeOnly = true) => {
    return async dispatch => {
      const query = new URLSearchParams()
      if(activeOnly) query.append('active_only', 'true')
      query.append('offset', offset)
      query.append('limit', limit)
      if(search.length) query.append('search', search)
      const fetchEmployees = createAsyncThunk(
        constants.FIND_ALL_EMPLOYEES,
        async (query) => {
          const response = await client.get(`/api/v2/my-ewa-employees?${query}`)
          return response.data
        }
      )
      dispatch(fetchEmployees(query))
    }
  },
  findOne: (id) => {
    return async dispatch => {
      const fetchEmployee = createAsyncThunk(
        constants.FIND_ONE_EMPLOYEE,
        async () => {
          const response = await client.get(`/api/v2/my-ewa-employees/${id}`)
          return response.data
        }
      )
      dispatch(fetchEmployee())
    }
  },
  findEmployeeAdvances: (employeeID, previousPayperiod) => {
    let url = `/api/v2/my-ewa-employees/${employeeID}/advances`
    if(previousPayperiod) url += `?previous_pay_period=${previousPayperiod}`
    return async dispatch => {
      const fetchEmployeeAdvances = createAsyncThunk(
        constants.FIND_EMPLOYEE_ADVANCES,
        async () => {
          const response = await client.get(url)
          return response.data
        }
      )
      dispatch(fetchEmployeeAdvances())
    }
  },
  resetAuth0MFA: (id) => {
    return async dispatch => {
      const resetMFA = createAsyncThunk(
        constants.RESET_AUTH0_MFA,
        async () => {
          const response = await client.delete(`/api/v2/my-ewa-employees/${id}/reset-phone-mfa`)
          return response.data
        }
      )
      dispatch(resetMFA())
    }
  },
  findEmployeeSafeguards: id => {
    return async dispatch => {
      const fetchEmployeeSafeguards = createAsyncThunk(
        constants.FIND_EMPLOYEE_SAFEGUARDS,
        async () => {
          const response = await client.get(`/api/v2/my-ewa-employees/${id}/employee-safeguards`)
          return response.data
        }
      )
      dispatch(fetchEmployeeSafeguards())
    }
  },
  updateEmployee: (id, data) => {
    return async dispatch => {
      const updateEmployee = createAsyncThunk(
        constants.UPDATE_EMPLOYEE,
        async () => {
          const response = await client.put(`/api/v2/my-ewa-employees/${id}`, data)
          return response.data
        }
      )
      dispatch(updateEmployee())
    }
  },
  updateEmployeeSafeguards: (id, data) => {
    return async dispatch => {
      const payload = {
        absolute_max_eligible_amount_per_pay_period: parseFloat(data.maxAdvance),
        earnings_percentage_limit: parseFloat(data.maxPercentage),
        max_hours_per_day: parseFloat(data.maxHours)
      }

      const updateEmployeeSafeguards = createAsyncThunk(
        constants.UPDATE_EMPLOYEE_SAFEGUARDS,
        async () => {
          const response = await client.put(`/api/v2/my-ewa-employees/${id}/employee-safeguards`, payload)
          return response.data
        }
      )
      dispatch(updateEmployeeSafeguards())
    }
  },
  toggleEmployeeStatus: (id, status) => {
    return async dispatch => {
      const toggleStatus = createAsyncThunk(
        constants.PATCH_EMPLOYEE_STATUS,
        async () => {
          const response = await client.patch(`/api/v2/my-ewa-employees/${id}/active`, { is_active: status })
          return response.data
        }
      )
      dispatch(toggleStatus())
    }
  },
  resetAllEmployees: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_ALL_EMPLOYEES })

    }
  },
  resetEmployee: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_EMPLOYEE })
    }
  },
  resetEmployeeAdvances: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_EMPLOYEE_ADVANCES })
    }
  },
  resetCreatedEmployee: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_CREATED_EMPLOYEE })
    }
  },
  resetSafeguards: () => {
    return async dispatch => {
      dispatch({ type: constants.RESET_EMPLOYEE_SAFEGUARDS })
    }
  },
  findMyCompanyRuleset: () => {
    return async dispatch => {
      const fetchCompanyRuleset = createAsyncThunk(
        constants.FIND_MY_COMPANY_RULESET,
        async () => {
          const response = await client.get('/api/v2/my-company-ruleset')
          return response.data
        }
      )
      dispatch(fetchCompanyRuleset())
    }
  }
})

export default api