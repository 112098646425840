import { configureStore } from '@reduxjs/toolkit'
import myEmployees from "../pages/my-employees/redux/reducer";
import mySafeguards from "../pages/safeguards/redux/reducer";
import myDashboard from "../pages/dashboard/redux/reducer";

// const store = createStore(storeReducers);
const store = configureStore({
  reducer: {
    myDashboard,
    myEmployees,
    mySafeguards
  }
})


export default store;