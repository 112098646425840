import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import styled from '@mui/material/styles/styled';
import OpenIcon from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import { useMemoScroll } from './useMemoScroll';
import Skeletonized from './skeleton';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}))

const StyledSummary = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}))

const StyledTools = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
}))

const ItemRow = styled('div')(({ theme }) => ({ 
  justifyContent: 'flex-start',
  display: 'flex',
  width: '100%',
  padding: '16px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 0',
  }
}))

const StyledField = styled(TextField)(({ theme }) => ({
  margin: '0 24px 24px 0',
  [theme.breakpoints.up('md')]: {
    margin: '0 24px 0 0',
  }
}))

const EmployeeAccordion = (props) => { 

  const ref = React.useRef(null)
  const showing = useMemoScroll(ref)

  const { 
    employee: {
      id,
      firstName,
      lastName,
      email,
      status,
      externalEmployeeId = '',
      updated_at,
    },
  } = props

  
  let isActive = status === 'active' ? true : false
  const navigate = useNavigate()


  return (
    <div ref={ref}>
      {showing ? (
        <>
        <StyledAccordion>
        <AccordionSummary>
          <StyledSummary>
            <Typography>{firstName} {lastName}</Typography>
            <StyledTools>
              <IconButton disabled>{isActive ? <ToggleOnIcon sx={{fontSize: '36px', color: '#546A7B'}}/> : <ToggleOffIcon  sx={{fontSize: '36px'}}/>}</IconButton>
              <IconButton
                onClick={() => navigate(`/my-employees/view/${id}`)}
              >
                <OpenIcon />
              </IconButton>
            </StyledTools>
          </StyledSummary>
          
        </AccordionSummary>
        <AccordionDetails>
          <ItemRow>
            <StyledField 
              label="Email" 
              value={email} 
              variant={'standard'}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
            />
            <StyledField
              label="External Employee ID"
              value={externalEmployeeId || ''}
              variant='standard'
              InputLabelProps={{
                readOnly: true,
              }}
              fullWidth
            />
            <StyledField 
              label="Last Updated"
              value={moment(updated_at).format('MM/DD/YYYY')}
              variant='standard'
              InputLabelProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </ItemRow>
        </AccordionDetails>
        
      </StyledAccordion>
      
    </>
      ) : <Skeletonized />}
    </div>
  )

}

export default EmployeeAccordion