import React from 'react'
import PageHeader from "../../../../components/page-header";
import {
  FormGroup,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material'
import styled from '@mui/material/styles/styled'
import withEmployeeActive from '../../sdk/withEmployeeActive'
import SaveSnackbar from '../../../../components/save-snackbar';

const StyledEmployee = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start'
}))



const EmployeeHeader = (props) => { 
  
  const [initialLoad, setInitialLoad] = React.useState(false) 

  const { 
    employeeActiveSDK: {
      employee,
      toggleEmployeeStatus,
      loadingActiveStatus
    } 
  } = props
  const [ show, setShow ] = React.useState(false)

  const {
    status,
    control_type
  } = employee
  const isAutomated = control_type === 'automated' ? true : false
  const isActive = status === 'active' ? true : false

  const toggleStatus = () => {
    toggleEmployeeStatus(employee.id, !isActive)
  } 

  React.useEffect(() => {
    if(!initialLoad) { return setInitialLoad(true) }
    if(loadingActiveStatus === 'fulfilled' || loadingActiveStatus === 'rejected') {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [loadingActiveStatus])


  return (
    <>
    <PageHeader>
        <StyledEmployee>
          <Typography variant="h5">Employee Details</Typography>
        </StyledEmployee>
        <FormGroup>
          <FormControlLabel 
            control={
              <Switch 
                checked={isActive} 
                onChange={toggleStatus}
              />
            } 
            label="Active" 
            disabled={isAutomated}
          />
        </FormGroup> 
      </PageHeader> 
      <SaveSnackbar 
          open={show} 
          setOpen={() => setShow(false)} 
          statusMessages={
            {
              success: `${employee.firstName} is now ${isActive ? 'active' : 'inactive'}`,
              error: `Failed to ${isActive ? 'terminate': 'activate'} ${employee.firstName}`
            }
          }
          severity={loadingActiveStatus === 'fulfilled' ? "success" : loadingActiveStatus === 'rejected' ? "error": ''}
        />
    </>
  )
}

export default withEmployeeActive(EmployeeHeader)