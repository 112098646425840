import constants from './constants'
import moment from 'moment'

const initState = {
  advancesSummaryStatus: 'idle',
  latestAdvancesStatus: 'idle',
  advancesSummary: {
    pay_period_start: '',
    pay_period_end: '',
    deduction_report_date: '',
    series: []
  },
  latestAdvances: []
}


const reducer = (state= initState, action) => {
  switch(action.type) {
    case `${constants.FIND_MY_COMPANY_ADVANCES_SUMMARY}/pending`: {
      return {
        ...state,
        advancesSummaryStatus: 'pending'
      }
    }
    case `${constants.FIND_MY_COMPANY_ADVANCES_SUMMARY}/fulfilled`: { 
      const { 
        pay_period_end,
        pay_period_start,
        series,
        deduction_report_date
      } = action.payload

      return {
        ...state,
        advancesSummaryStatus: 'fulfilled',
        advancesSummary: {
          pay_period_end,
          pay_period_start,
          deduction_report_date,
          series
        }
      }
    }
    case `${constants.FIND_MY_COMPANY_ADVANCES_SUMMARY}/rejected`: {
      return {
        ...state,
        advancesSummaryStatus: 'rejected'
      }
    }
    case `${constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES}/pending`: {
      return {
        ...state,
        latestAdvancesStatus: 'pending'
      }
    }
    case `${constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES}/fulfilled`: { 
      return {
        ...state,
        latestAdvancesStatus: 'fulfilled',
        latestAdvances: action.payload
        //todo remove this mocking data
        // latestAdvances: action.payload.map((advance, index) => ({
        //   ...advance,
        //   created_at: moment().subtract('minutes', index + 1).format('YYYY-MM-DDTHH:mm:ss.sssZ')
        // }))
      }
    }
    case `${constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES}/rejected`: {
      return {
        ...state,
        latestAdvancesStatus: 'rejected'
      }
    }
    default: return {...state}
  }
}

export default reducer