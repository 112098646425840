import Skeleton from '@mui/material/Skeleton';
import styled from '@mui/material/styles/styled'

const StyledAccordion = styled('div')(({ theme }) => ({
  width: '100%',
  marginBottom: theme.spacing(1),
}))


const Skeletonized = () => {
  return (
    <StyledAccordion><Skeleton variant="rectangular" width={'100%'} height={65} /></StyledAccordion>
  )
}

export default Skeletonized;