import constants from './constants'

const initState = {
  employeeStatus: 'idle',
  employeesStatus: 'idle',
  employeeAdvancesStatus: 'idle',
  employeeSafeguardsStatus: 'idle',
  createEmployeeStatus: 'idle',
  employeeActiveStatus: 'idle',
  employeeUpdateStatus: 'idle',
  employees: {
    all: []
  },
  employee: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    externalEmployeeId: '',
    employmentType: '',
    updated_at: '',
    position: '',
    hourlyWage: '',
    salary: '',
    status: ''
  },
  employeeAdvances: {
    payPeriodStart: '',
    payPeriodEnd: '',
    data: []
  },
  employeeSafeguards: {
    maxAdvance: 0,
    maxHours: 0,
    maxPercentage: 0,
    companyHardLimits: {
      maxAdvance: 0,
      maxHours: 0,
      maxPercentage: 0
    }
  },
  createdEmployee: null,
  companyRules: {
    directoryControlType: 'automated'
  }
}

const reducer = (state= initState, action) => {
  switch(action.type) {
    case `${constants.FIND_ALL_EMPLOYEES}/pending`: {
      return {
        ...state,
        employeesStatus: 'pending',
        employees: {
          ...state.employees
        }
      }
    }
    case `${constants.FIND_ALL_EMPLOYEES}/fulfilled`: {
      return {
        ...state,
        employeesStatus: 'fulfilled',
        employees: {
          limit: action.payload.limit,
          offset: action.payload.offset,
          total_count: action.payload.total_count,
          all: [
            ...state.employees.all, 
            ...action.payload.data.map(employee => ({
              id: employee.id,
              firstName: employee.first_name,
              lastName: employee.last_name,
              email: employee.email,
              phone: employee.phone,
              externalEmployeeId: employee.external_employee_id,
              employmentType: employee.employment_type,
              updated_at: employee.updated_at,
              status: employee.status,
              position: employee.position,
              hourlyWage: employee.hourly_wage,
              salary: employee.salary,
            }))
          ]
        }
      }
    }
    case `${constants.FIND_ONE_EMPLOYEE}/pending`: {
      return {
        ...state,
        employeeStatus: 'pending',
        employee: {
          ...state.employee,
        }
      }
    }
    case `${constants.FIND_ONE_EMPLOYEE}/fulfilled`: {
      return {
        ...state,
        employeeStatus: 'fulfilled',
        employee: {
          id: action.payload.id,
          firstName: action.payload.first_name,
          lastName: action.payload.last_name,
          email: action.payload.email,
          phone: action.payload.phone,
          externalEmployeeId: action.payload.external_employee_id,
          employmentType: action.payload.employment_type,
          updated_at: action.payload.updated_at,
          position: action.payload.position,
          hourlyWage: action.payload.hourly_wage,
          salary: action.payload.salary,
          status: action.payload.status,
          control_type: action.payload.control_type,
          payrollId: action.payload.payroll_id
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_ADVANCES}/pending`: {
      return {
        ...state,
        employeeAdvancesStatus: 'pending',
        employeeAdvances: {
          ...state.employeeAdvances,
          data: []
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_ADVANCES}/fulfilled`: {
      return {
        ...state,
        employeeAdvancesStatus: 'fulfilled',
        employeeAdvances: {
          payPeriodEnd: action.payload.pay_period_end,
          payPeriodStart: action.payload.pay_period_start,
          data: action.payload.data.sort((a,b) => b.created_at.localeCompare(a.created_at))
        }
      }
    }
    case `${constants.RESET_ALL_EMPLOYEES}`: { 
      return {
        ...state,
        employeesStatus: 'idle',
        employees: {
          all: []
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_SAFEGUARDS}/pending`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'pending',
        employeeSafeguards: {
          ...state.employeeSafeguards
        }
      }
    }
    case `${constants.FIND_EMPLOYEE_SAFEGUARDS}/fulfilled`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'fulfilled',
        employeeSafeguards: {
          maxAdvance: action.payload.absolute_max_eligible_amount_per_pay_period,
          maxHours: action.payload.max_hours_per_day,
          maxPercentage: action.payload.earnings_percentage_limit,
          companyHardLimits: {
            maxAdvance: action.payload.limits.absolute_max_eligible_amount_per_pay_period,
            maxHours: action.payload.limits.max_hours_per_day,
            maxPercentage: action.payload.limits.earnings_percentage_limit
          }
        }
      }
    }
    case `${constants.UPDATE_EMPLOYEE_SAFEGUARDS}/pending`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'pending'
      }
    }
    case `${constants.UPDATE_EMPLOYEE_SAFEGUARDS}/fulfilled`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'fulfilled',
        employeeSafeguards: {
          maxAdvance: action.payload.absolute_max_eligible_amount_per_pay_period,
          maxHours: action.payload.max_hours_per_day,
          maxPercentage: action.payload.earnings_percentage_limit,
          companyHardLimits: {
            maxAdvance: action.payload.limits.absolute_max_eligible_amount_per_pay_period,
            maxHours: action.payload.limits.max_hours_per_day,
            maxPercentage: action.payload.limits.earnings_percentage_limit
          }
        }
      }
    }
    case `${constants.UPDATE_EMPLOYEE_SAFEGUARDS}/rejected`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'rejected'
      }
    }
    case `${constants.CREATE_EMPLOYEE}/pending`: {
      return {
        ...state,
        createEmployeeStatus: 'pending'
      }
    }
    case `${constants.CREATE_EMPLOYEE}/fulfilled`: {
      return {
        ...state,
        createEmployeeStatus: 'fulfilled',
        createdEmployee: {
          ...action.payload
        }
      }
    }
    case `${constants.CREATE_EMPLOYEE}/rejected`: {
      return {
        ...state,
        createEmployeeStatus: 'rejected'
      }
    }
    case `${constants.RESET_CREATED_EMPLOYEE}`: {
      return {
        ...state,
        createEmployeeStatus: 'idle',
        createdEmployee: null
      }
    }
    case `${constants.RESET_EMPLOYEE_SAFEGUARDS}`: {
      return {
        ...state,
        employeeSafeguardsStatus: 'idle',
        employeeSafeguards: {
          maxAdvance: 0,
          maxHours: 0,
          maxPercentage: 0,
          companyHardLimits: {
            maxAdvance: 0,
            maxHours: 0,
            maxPercentage: 0
          }
        }
      }
    }
    case `${constants.RESET_EMPLOYEE}`: {
      return {
        ...state,
        employeeStatus: 'idle',
        employee: {
          id: '',
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          externalEmployeeId: '',
          employmentType: '',
          updated_at: '',
          position: '',
          hourlyWage: '',
          salary: '',
          status: ''
        }
      }
    }
    case `${constants.PATCH_EMPLOYEE_STATUS}/pending`: {
      return {
        ...state,
        employeeActiveStatus: 'pending'
      }
    }
    case `${constants.PATCH_EMPLOYEE_STATUS}/fulfilled`: {
      return {
        ...state,
        employeeActiveStatus: 'fulfilled',
        employee: {
          ...state.employee,
          status: action.payload.status
        }
      }
    }
    case `${constants.PATCH_EMPLOYEE_STATUS}/rejected`: {
      return {
        ...state,
        employeeActiveStatus: 'rejected'
      } 
    }
    case `${constants.UPDATE_EMPLOYEE}/pending`: {
      return {
        ...state,
        employeeUpdateStatus: 'pending'
      }
    }
    case `${constants.UPDATE_EMPLOYEE}/fulfilled`: {
      return {
        ...state,
        employeeUpdateStatus: 'fulfilled',
        employee: {
          ...state.employee,
          ...action.payload
        }
      }
    }
    case `${constants.UPDATE_EMPLOYEE}/rejected`: {
      return {
        ...state,
        employeeUpdateStatus: 'rejected'
      }
    }
    case `${constants.FIND_MY_COMPANY_RULESET}/pending`: {
      return state
    }
    case `${constants.FIND_MY_COMPANY_RULESET}/fulfilled`: {
      return {
        ...state,
        companyRules: {
          directoryControlType: action.payload.directory_control_type
        }
      }
    }
    case `${constants.FIND_MY_COMPANY_RULESET}/rejected`: {
      return state
    }
    default: return {...state}
  }

}

export default reducer