import React from "react";
import { styled } from '@mui/material/styles'
import Cloud from '../../components/icons/clouds'
import { PropTypes } from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import moment from 'moment'


const getGreeting = (firstName, content) => {
  const currentHour = moment().hour()
  let greeting = ''

  if (currentHour < 12) {
    greeting = "Good morning"
  } else if (currentHour < 18) {
    greeting = "Good afternoon"
  } else {
    greeting = "Good evening"
  }

  if (firstName) greeting = `${greeting}, ${firstName}`

  return greeting
}

const getDaysFromNow = (date) => {
  const now = moment()
  const end = moment(date)
  return end.diff(now, 'days') + 1
}

const numberToMonth =  ({
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
})

const numberToDay =  ({
  1: '1st',
  2: '2nd',
  3: '3rd',
  4: '4th',
  5: '5th',
  6: '6th',
  7: '7th',
  8: '8th',
  9: '9th',
  10: '10th',
  11: '11th',
  12: '12th',
  13: '13th',
  14: '14th',
  15: '15th',
  16: '16th',
  17: '17th',
  18: '18th',
  19: '19th',
  20: '20th',
  21: '21st',
  22: '22nd',
  23: '23rd',
  24: '24th',
  25: '25th',
  26: '26th',
  27: '27th',
  28: '28th',
  29: '29th',
  30: '30th',
  31: '31st',
})

const prettify = (date) => {
  const [ month, day ] = date.split('/')
  return `${numberToMonth[parseInt(month)]} ${numberToDay[parseInt(day)]}`
}
const getPayPeriodText = (pay_period_end, deduction_report_date) => {
  return `The pay period ends on ${prettify(pay_period_end)}. Your deduction report will be emailed on ${prettify(deduction_report_date)}.`
}

const Welcome = styled('h5')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  ...theme.typography.h5,
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 4,
  [theme.breakpoints.up('sm')]: {
    fontSize: '32px' 
  }
}))

const Subtext = styled('p')(({ theme }) => ({ 
  fontFamily: theme.typography.fontFamily,
  ...theme.typography.p,
  paddingBottom: 16,
  [theme.breakpoints.up('sm')]: {
    fontSize: '18px'
  }
}))

const StyledCloud = styled(Cloud)(() => ({
  marginLeft: '8px',
  width: '24px',
  height: '24px',
}))

const Component = styled('div')(({ theme }) => ({ 
  paddingTop: 16,
  [theme.breakpoints.up('sm')]: {
    paddingTop: 40,
    paddingBottom: 24
  }
}))



function WelcomeHeader(props) {
  const {sdk: {
    advancesSummary: {
      pay_period_end,
      deduction_report_date
    }
  }} = props
  const { user = {} } = useAuth0()
  const { user: { firstName = '' } } = user

  
  return (
    <Component>
      <Welcome>{getGreeting(firstName, 'hooled')} <StyledCloud /></Welcome>
      {!!pay_period_end && !!deduction_report_date && <Subtext>{getPayPeriodText(pay_period_end, deduction_report_date)}</Subtext>}
    </Component>
  );
}

export default WelcomeHeader;