const constants = {
  FIND_ALL_EMPLOYEES: 'my-employees/FIND_ALL_EMPLOYEES',
  FIND_ONE_EMPLOYEE: 'my-employees/FIND_ONE_EMPLOYEE',
  RESET_ALL_EMPLOYEES: 'my-employees/RESET_ALL_EMPLOYEES',
  FIND_EMPLOYEE_ADVANCES: 'my-employees/FIND_EMPLOYEE_ADVANCES',
  RESET_EMPLOYEE: 'my-employees/RESET_EMPLOYEE',
  RESET_EMPLOYEE_ADVANCES: 'my-employees/RESET_EMPLOYEE_ADVANCES',
  FIND_EMPLOYEE_SAFEGUARDS: 'my-employees/FIND_EMPLOYEE_SAFEGUARDS',
  CREATE_EMPLOYEE: 'my-employees/CREATE_EMPLOYEE',
  RESET_CREATED_EMPLOYEE: 'my-employees/RESET_CREATE_EMPLOYEE',
  RESET_EMPLOYEE_SAFEGUARDS: 'my-employees/RESET_EMPLOYEE_SAFEGUARDS',
  PATCH_EMPLOYEE_STATUS: 'my-employees/PATCH_EMPLOYEE_STATUS',
  UPDATE_EMPLOYEE: 'my-employees/UPDATE_EMPLOYEE',
  UPDATE_EMPLOYEE_SAFEGUARDS: 'my-employees/UPDATE_EMPLOYEE_SAFEGUARDS',
  FIND_MY_COMPANY_RULESET: 'my-employees/FIND_MY_COMPANY_RULESET',
}
export default constants