import React from "react";
import { 
  Typography, 
  TextField, 
  Paper,
  Button,
  ButtonGroup,
  InputAdornment,
  FormControl,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import styled from '@mui/material/styles/styled'
import withCreateEmployee from "../sdk/withCreateEmployee";
import SaveSnackbar from "../../../components/save-snackbar";
import { validate } from 'email-validator'

const StyledRow = styled('div')(({ theme }) => ({
  justifyContent: 'flex-start',
  display: 'flex',
  width: '100%',
  padding: '16px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 0',
  }
}))

const StyledField = styled(TextField)(({ theme }) => ({
  margin: '0 32px 12px 0',
}))

const StyledFieldCapitalized = styled(TextField)(({ theme }) => ({
  margin: '0 32px 12px 0',
  '& input': {
    textTransform: 'capitalize'
  }
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: "16px 0"
}))


const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: '0 32px 12px 0',
}))


const StyledHeader = styled('div')(({ theme }) => ({ 
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '16px'
}))

const EmployeeDetails = props => {
  
  const {
    employeeSDK: {
      create,
      loadingStatus,
      createdEmployee,
      resetCreatedEmployee,
      resetSafeguards    
    }
  } = props
  
  const formRef = React.useRef(null)
  const firstNameRef = React.useRef(null)
  const lastNameRef = React.useRef(null)
  const externalIdRef = React.useRef(null)
  const emailRef = React.useRef(null)
  const phoneRef = React.useRef(null)
  const positionRef = React.useRef(null)
  const compensationTypeRef = React.useRef(null)
  const wageRef = React.useRef(null)
  const payrollIdRef = React.useRef(null)

  const [ empType, setEmpType ] = React.useState('hourly')
  const [ showSnackbar, setShowSnackbar ] = React.useState({show: false, severity: 'info', message: ''})
  const [ wageError, setWageError ] = React.useState(false)
  const [ emailError, setEmailError ] = React.useState(false)

  React.useEffect(() => {
    return () => {
      resetCreatedEmployee()
      resetSafeguards()
    }
  }, [])

  React.useEffect(() => {
    if(!!createdEmployee && !showSnackbar.show && loadingStatus === 'fulfilled'){
      setShowSnackbar({show: true, severity: 'success', message: 'Employee Created'})
    } else if(loadingStatus === 'rejected') {
      setShowSnackbar({show: true, severity: 'error', message: 'Error Creating Employee'})
    } else {
      setShowSnackbar({show: false, severity: 'success', message: 'Placeholder'})
    }
  }, [loadingStatus])

  const onSave = (e) => {
    e.preventDefault()
    const payload = makePayload(e)
    create(payload)
  }

  const makePayload = (e) => ({
    first_name: firstNameRef.current.querySelector('input').value,
    last_name: lastNameRef.current.querySelector('input').value,
    external_employee_id: externalIdRef.current.querySelector('input').value,
    email: emailRef.current.querySelector('input').value,
    phone: phoneRef.current.querySelector('input').value,
    position: positionRef.current.querySelector('input').value,
    employment_type: compensationTypeRef.current.querySelector('select').value,
    payroll_id: payrollIdRef.current.querySelector('input').value,
    ...getCompensations(compensationTypeRef.current.querySelector('select').value, wageRef.current.querySelector('input').value)
  })

  const getCompensations = (type, value) => {
    if (type === 'hourly') {
      return {hourly_wage: value, salary: 0}
    } else if(type === 'salary') {
      return {hourly_wage: 0, salary: value}
    } else return { hourly_wage: 0, salary: 0 }
  }

  const clearForm = () => {
    formRef.current.reset()
    setWageError(false)
    setEmailError(false)
    resetCreatedEmployee()
    resetSafeguards()
  }

  const checkForNumbers = (e) => {
    const { value } = e.target
    const isAllNumbers =  /^\d+$/.test(value)
    setWageError(!isAllNumbers)
  }

  const checkForEmail = (e) => {
    const { value } = e.target
    const isValidEmail = validate(value)
    setEmailError(!isValidEmail)
  }

  const debounceEmail = () => {
    let timer
    return (e) => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        checkForEmail(e)
      }, 750)
    }
  }



  return (
      <StyledForm
        onSubmit={onSave}
        ref={formRef}
      >
        <StyledHeader>
          <Typography variant="h6" color='primary'>Employee Information</Typography>
          <Button
            onClick={clearForm}
            variant="outlined"
            disabled={loadingStatus !== 'fulfilled'}
          >
            Create Another
          </Button>
        </StyledHeader>
        <Paper sx={{padding: '16px'}}>
          <StyledRow>
            <StyledFieldCapitalized 
              fullWidth
              required
              label="First Name"
              variant='standard'
              ref={firstNameRef}
            />
            <StyledFieldCapitalized
              fullWidth
              required
              label="Last Name"
              variant='standard'
              ref={lastNameRef}
            />
          </StyledRow>
          <StyledRow>
          <StyledField
              fullWidth
              required
              label="Email"
              variant='standard'
              type='email'
              ref={emailRef}
              onChange={debounceEmail()}
              error={emailError}
              helperText={emailError ? 'Invalid Email' : ''}
            />
            <StyledField
              fullWidth
              label="External Employee ID"
              variant='standard'
              required
              ref={externalIdRef}
            />            
            <StyledField
              fullWidth
              label="Payroll ID"
              variant='standard'
              ref={payrollIdRef}
            />
            <StyledField
              fullWidth
              label="Contact Phone"
              variant='standard'
              type='tel'
              ref={phoneRef}
            />
          </StyledRow>
          <StyledRow>
            <StyledFieldCapitalized
              fullWidth
              label="Position"
              variant='standard'
              required
              ref={positionRef}
            />
            <StyledFormControl  fullWidth>
              <InputLabel 
                required 
                variant="standard" 
                htmlFor="uncontrolled-native"
              >
                Compensation Type
              </InputLabel>
              <NativeSelect
                onChange={(e) => setEmpType(e.target.value.toLowerCase())}
                inputProps={{
                  name: 'compensationType',
                  id: 'uncontrolled-native',
                }}
                ref={compensationTypeRef}
              >
                <option value={'hourly'}>Hourly</option>
                <option value={'salary'}>Salary</option>
              </NativeSelect>
            </StyledFormControl>
            <StyledField
              fullWidth
              required
              label={empType === 'hourly' ? 'Hourly' : 'Salary'}
              onChange={checkForNumbers}
              error={wageError}
              helperText={wageError ? 'Must be a number' : ''}
              variant='standard'
              ref={wageRef}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
              
            />
          </StyledRow>
          <ButtonGroup sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Button
              onClick={clearForm}
              variant="outlined"
              disabled={!!createdEmployee}
            >
              Clear
            </Button>
            <Button 
              variant="contained"
              type="submit"
              disabled={!!createdEmployee || wageError || emailError}
            >
              Save
            </Button>
          </ButtonGroup>
        </Paper>
        <SaveSnackbar 
          open={showSnackbar.show}
          setOpen={(show) => {
            setShowSnackbar({...showSnackbar, show})
          }}
          statusMessages={{
            success: 'Employee Created',
            error: 'Error Creating Employee'
          }}
          severity={showSnackbar.severity}
        />
      </StyledForm>
  )
}

export default withCreateEmployee(EmployeeDetails)