import React from 'react'
import { Box,} from '@mui/material'
import EmployeeHeader from './employee-header'
import EmployeeDetails from './employee-details'
import EmployeeAdvances from './employee-advances'
import EmployeeSafeguards from './employee-safeguards'

const Employee = props => {
  return (
    <Box>
      <EmployeeHeader />
      <EmployeeDetails/>
      <EmployeeSafeguards />
      <EmployeeAdvances />
    </Box>
  )
}

export default Employee