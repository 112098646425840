import React from 'react'
import { 
  Paper,
  Typography,
  TextField,
  Button,
  ButtonGroup,
  InputAdornment,
} from '@mui/material'
import styled from '@mui/material/styles/styled'
import withEmployeeSafeGuards from '../../sdk/withEmployeeSafeguards'
import { useLocation } from 'react-router-dom'
import SaveSnackbar from '../../../../components/save-snackbar'
import InfoNotification from '../../../../components/notification';
import Skeletonized from './skeletonized'

const StyledField = styled(TextField)(({ theme }) => ({
  margin: '0 32px 12px 0',
}))

const StyledForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: "16px 0"
}))

const StyledRow = styled('div')(({ theme }) => ({
  justifyContent: 'flex-start',
  display: 'flex',
  width: '100%',
  padding: '16px',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    padding: '16px 0',
  }
}))

const safeguards = {
  success: 'Safeguards updated successfully',
  error: 'There was an error updating the safeguards'
}

const alertTypes = {
  fulfilled: 'success',
  rejected: 'error'
}

const StyledHeader = styled('div')(({ theme }) => ({ 
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-staft',
  justifyContent: 'flex-start'
}))

const Safeguards = props => {
  const [ cancelLoad, setCancelLoad] = React.useState(false)
  const [ safeguardLoad, setSafeguardLoad] = React.useState(false)
  const [ lastStatus, setLastStatus ] = React.useState('idle')
  const [ open, setOpen ] = React.useState(false)
  const [ maxHoursError, setMaxHoursError ] = React.useState(null)
  const [ maxAdvanceError, setMaxAdvanceError ] = React.useState(null)
  const [ maxPercentageError, setMaxPercentageError ] = React.useState(null)
  const [ formValues, setFormValues ] = React.useState({})

  const { 
    employeeSDK: {
      loadingStatus,
      employmentType,
      employeeSafeguards: {
        maxAdvance,
        maxHours,
        maxPercentage,
        companyHardLimits
      }, 
      findEmployeeSafeguards,
      updateEmployeeSafeguards
    }
  } = props

  
  const location = useLocation()
  const [ , , ,id ] = location.pathname.split('/')
  
  React.useEffect(() => {    
    findEmployeeSafeguards(id)
  }, [])

  React.useEffect(() => { 
    if(loadingStatus !== 'pending' && lastStatus === 'pending' && safeguardLoad){
      setOpen(true)
      setSafeguardLoad(false)
    }
    setLastStatus(loadingStatus)
  }, [loadingStatus])

  React.useEffect(() => { 
    setFormValues({maxAdvance, maxHours, maxPercentage})
  }, [maxHours, maxAdvance, maxPercentage])
  if(loadingStatus === 'pending' && !(cancelLoad || safeguardLoad)) return (<Skeletonized />)

  const formRef = React.createRef()

  
  const onCancel = () => {
    setMaxHoursError(null)
    setMaxAdvanceError(null)
    setMaxPercentageError(null)
    setCancelLoad(true)
    findEmployeeSafeguards(id)
    formRef.current.reset()
  }

  const onSaveSafegauards = () => {
    setSafeguardLoad(true)
    updateEmployeeSafeguards(id, formValues)
  }

  const pluralize = (word, value) => parseInt(value) !== 1 ? `${word}s` : word 

  const isAllNumbers = value => /^\d+$/.test(value) ? null : 'Must be a number'
  const isBelowLimit = (limit, display) => value => value <= limit ? null : `Must be at or below ${display}`
  
  const maxHoursCheck = e => {
    const { value, name } = e.target
    setMaxHoursError(isAllNumbers(value) || isBelowLimit(companyHardLimits.maxHours, `${companyHardLimits.maxHours} ${pluralize('hour', companyHardLimits.maxHours)}`)(value))
    setFormValues({...formValues, [name]: value})
  }

  const maxAdvanceCheck = e => {
    const { value, name } = e.target
    setMaxAdvanceError(isAllNumbers(value) || isBelowLimit(companyHardLimits.maxAdvance, `$${companyHardLimits.maxAdvance}`)(value))
    setFormValues({...formValues, [name]: value})
  }

  const maxPercentageCheck = e => { 
    const { value, name } = e.target
    setMaxPercentageError(isAllNumbers(value) || isBelowLimit(companyHardLimits.maxPercentage, `${companyHardLimits.maxPercentage}%`)(value))
    setFormValues({...formValues, [name]: value})
  }

  const localSetOpen = (option = false) => {
    setSafeguardLoad(false)
    setOpen(option)
  }

  return (
    <StyledForm ref={formRef}>
      <StyledHeader>
        <Typography variant="h6" sx={{marginBottom: '16px'}}  color='primary'>Individual Safeguards</Typography>
        <InfoNotification message={(
          <div>
            These safeguards are specific to this employee and will affect the employees eligilbity amount. 
            <p>The individual upper bound is limited to your company safeguard limits:</p>
            <ul>
              <li>Max hours/Day: {companyHardLimits.maxHours} hours</li>
              <li>Max Amount/Pay Period: ${companyHardLimits.maxAdvance}</li>
              <li>Max Percentage of Gross Pay: {companyHardLimits.maxPercentage}%</li>
            </ul>
          </div>
        )} />
      </StyledHeader>
      <Paper sx={{padding: '16px'}}>
        <StyledRow>
          <StyledField 
            fullWidth
            required
            name="maxHours"
            label="Max Hours per Day"
            defaultValue={maxHours}
            variant='standard'
            onChange={maxHoursCheck}
            error={!!maxHoursError}
            helperText={maxHoursError || ''}
            InputProps={{
              readOnly: employmentType !== 'hourly',
              endAdornment: <InputAdornment position="start">Hours</InputAdornment>
            }}
          />
          <StyledField 
            fullWidth
            required
            name="maxAdvance"
            label="Max Amount per Pay Period"
            defaultValue={maxAdvance}
            variant='standard'
            onChange={maxAdvanceCheck}
            error={!!maxAdvanceError}
            helperText={maxAdvanceError || ''}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
          />
          <StyledField 
            fullWidth
            required
            name="maxPercentage"
            label="Max Percentage of Gross Pay"
            defaultValue={maxPercentage}
            variant='standard'
            onChange={maxPercentageCheck}
            error={!!maxPercentageError}
            helperText={maxPercentageError || ''}
            InputProps={{
              endAdornment: <InputAdornment position="start">%</InputAdornment>
            }}
          />
        </StyledRow>
        <ButtonGroup sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Button
              onClick={onCancel}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              onClick={onSaveSafegauards}
              disabled={!!maxHoursError || !!maxAdvanceError || !!maxPercentageError}
            >
              Save
            </Button>
          </ButtonGroup>
      </Paper>
      <SaveSnackbar  
        severity={alertTypes[loadingStatus]}
        statusMessages={safeguards}
        open={open}
        setOpen={localSetOpen}
      />
    </StyledForm>
  )
}

export default withEmployeeSafeGuards(Safeguards)