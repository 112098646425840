import React from "react";
import EmployeeSummary from "./employee-summary";
import { Box, Typography, Button, Switch } from "@mui/material";
import PageHeader from "../../components/page-header";
import withEmployees from "./sdk/withEmployees";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "@mui/material/styles/styled";
import SearchBar from "../../components/search-bar";
import InfoNotification from "../../components/notification";
import LoadingSection from "../../components/loading-section";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const InfiniteStyled = styled("div")(() => ({
  width: "100%",
  alignItems: "center",
  maxWidth: "calc(1035px - 18px)",
  // height: '100vh',
  "& > div": {
    width: "100%",
  },
  "& >  div > div": {
    padding: "0 1px",
    overflow: "unset !important",
  },
}));

const StyledEmployees = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
}));

const StyledHeaderRow = styled("div")(({ theme }) => ({
  position: "sticky",
  top: 209,
  zIndex: 1,
  maxWidth: "1020px",
  backgroundColor: "white",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  display: "flex",
  gap: "26px",
  padding: "12px 24px",
  marginBottom: "16px",
  [theme.breakpoints.up("md")]: {
    top: 135,
    padding: "24px 0px",
  },
}));

const StyledHint = styled("span")(({ theme }) => ({
  display: "none",
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

const MyEmployees = (props) => {
  const [offset, setOffset] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [switchState, setSwitchState] = React.useState(true);
  const navigate = useNavigate();
  const LIMIT = 100;
  const {
    employeesSDK: {
      employees: { all, total_count },
      findAll,
      resetAllEmployees,
      resetEmployee,
      resetEmployeeAdvances,
      employeesStatus,
      findMyCompanyRuleset,
      directoryControlType,
    },
  } = props;

  React.useEffect(() => {
    resetAllEmployees();
    resetEmployee();
    resetEmployeeAdvances();
    findAll(offset, LIMIT, search, switchState);
  }, [switchState]);

  React.useEffect(() => {
    window.scroll(0, 0);
    findMyCompanyRuleset();
  }, []);

  const hasMore = total_count > all.length;
  const onReady = (text) => {
    if (text !== search) {
      resetAllEmployees();
      findAll(0, LIMIT, text, !switchState);
      return setSearch(text);
    }

    findAll(offset + LIMIT, LIMIT, text, switchState);
    setOffset(offset + LIMIT);
  };

  return (
    <Box>
      <PageHeader>
        <StyledEmployees>
          <Typography variant="h5">Employees</Typography>
          {directoryControlType === "automated" && (
            <InfoNotification message="Your employees are read only in the portal. To update any employee detail, you will need to update your system providing StablPay the users eligiblity records." />
          )}
        </StyledEmployees>
        <SearchBar onReady={onReady} />
      </PageHeader>
      <StyledHeaderRow>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "space-between",
            justifyContent: "flex-start",
          }}
        >
          <Switch
            checked={switchState}
            onChange={() => setSwitchState(!switchState)}
          />
          <Typography variant="h5">{switchState ? "Active" : "All"}</Typography>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/my-employees/add")}
          disabled={directoryControlType === "automated"}
        >
          <AddIcon />
          Add Employee
        </Button>
      </StyledHeaderRow>
      <InfiniteStyled>
        <InfiniteScroll
          dataLength={all.length}
          next={() => {
            if (employeesStatus === "pending") return;
            findAll(offset + LIMIT, LIMIT);
            setOffset(offset + LIMIT);
          }}
          scrollThreshold={0.5}
          hasMore={hasMore}
          loader={<LoadingSection />}
          endMessage={
            search !== "" &&
            employeesStatus === "fulfilled" && (
              <Typography sx={{ paddingTop: "24px" }}>
                Showing {total_count} of {total_count} records.
              </Typography>
            )
          }
        >
          {all.map((employee) => {
            return <EmployeeSummary employee={employee} key={employee.id} />;
          })}
        </InfiniteScroll>
      </InfiniteStyled>
    </Box>
  );
};

export default withEmployees(MyEmployees);
