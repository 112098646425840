import React from 'react' 
import withEmployeeAdvances from '../../sdk/withEmployeeAdvances'
import { useLocation } from 'react-router-dom'  
import { Typography, Paper, Box } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styled from '@mui/material/styles/styled'
import moment from 'moment'
import IconButton from '@mui/material/IconButton'
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';

const makeMoneyPretty = dollars => dollars.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
const dateFormat = date => new Date(date).toLocaleDateString()
const toDayStr = day => moment(day, 'DD').format('Do')

const prettyDateStr = date => {
  const [year, month, day] = date.split('-')
  return `${moment(month, 'MM').format('MMM')} ${toDayStr(day)}`
}

const StyledStatusCell = styled(TableCell)(({ theme }) => ({ 
  textTransform: 'capitalize',
  width: '100px',
  maxWidth: '100px',
  borderRight: '1px solid rgba(224, 224, 224, 1) !important',
  display: 'none',
  [theme.breakpoints.up('xs')]: {
    display: 'table-cell',
  }
}))

const StyledCreatedCell = styled(TableCell)(({ theme }) => ({
  // width: '100px',
  // maxWidth: '100px'
}))

const StyledIDCell = styled(TableCell)(({ theme }) => ({
  width: '120px',
  maxWidth: '120px',
  borderRight: '1px solid rgba(224, 224, 224, 1) !important'
}))

const StyledMoneyCell = styled(TableCell)(({ theme }) => ({
  width: '155px',
  borderRight: '1px solid rgba(224, 224, 224, 1) !important'
}))

const StyledSectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  [theme.breakpoints.up('xs')]: {
    alignItems: 'flex-end',
  }
}))

const StyledButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& p': {
    marginRight: '8px',
    display: 'none'
  },
  [theme.breakpoints.up('xs')]: {
    '& p': {
      display: 'block'
    }
  }
}))

const MobileTable = styled(Table)(({ theme }) => ({
  minWidth: '100%',  
}))

const statusToHuman = status => {
  switch(status.toLowerCase()) {
    case 'approved':
    case 'pending': return 'Pending'
    case 'manual': 
    case 'paid_out': return 'Paid Out'
    case 'denied': return 'Ineligible'
    default: return status
  }
}

const createPayPeriodString = (start, end) => {
  let pps = prettyDateStr(start)
  let ppe = prettyDateStr(end)
  if(/invalid/i.test(pps) || /invalid/i.test(ppe)) return ''
  let str = `${prettyDateStr(start)} to ${prettyDateStr(end)}`
  return str
}

const EmployeeAdvances = (props) => {
  const { pathname } = useLocation()
  const [ pageEmployeeID ] = pathname.split('/').reverse()
  const { 
    employeeSDK: { 
      employeeAdvances: { 
        payPeriodStart, 
        payPeriodEnd, 
        data
      }, 
      findEmployeeAdvances, 
    } } = props

  const navigate = useNavigate()
  React.useEffect(() => {
    findEmployeeAdvances(pageEmployeeID)
  }, [])

      
  const summary = makeMoneyPretty(data.reduce((acc, advance) => advance.amount.cents + acc, 0)/100)
  return (
    <Box>
      <StyledSectionHeader>
        <Box>
          <Typography variant="h6"  color='primary'>Current Pay Period Advances</Typography>
          <Typography color='primary' >Pay Period: {createPayPeriodString(payPeriodStart, payPeriodEnd)}</Typography>
          <Typography sx={{marginBottom: '16px'}}  color='primary'>Total: ${summary}</Typography>
        </Box>
        <StyledButtonBox>
          <IconButton
            onClick={() => navigate('previous-advances?previousPayperiod=1')}
            disabled={payPeriodStart === '' || payPeriodEnd === ''}
          >
            <Typography variant="body1">View Previous </Typography>
            <LaunchIcon /> 
          </IconButton>
        </StyledButtonBox>
      </StyledSectionHeader>
      
      <TableContainer component={Paper}>
        <MobileTable>
          <TableHead>
            <TableRow>
              <StyledIDCell>StablPay ID</StyledIDCell>
              <StyledMoneyCell>Advance Amount</StyledMoneyCell>
              <StyledStatusCell>Status</StyledStatusCell>
              <StyledCreatedCell align="right">Created</StyledCreatedCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 && <TableRow><TableCell colSpan={4}>No advances taken</TableCell></TableRow>}
            {data.map((row, index) => (

              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0,  } }}
              >
                <StyledIDCell>{row.id}</StyledIDCell>
                <StyledMoneyCell>
                  ${makeMoneyPretty(row.amount.cents/100)}
                </StyledMoneyCell>  
                <StyledStatusCell>{statusToHuman(row.status)}</StyledStatusCell>           
                <StyledCreatedCell align="right">{dateFormat(row.created_at)}</StyledCreatedCell>
              </TableRow>
            ))}
          </TableBody>
        </MobileTable>
      </TableContainer>
    </Box>
  )
}

export default withEmployeeAdvances(EmployeeAdvances)