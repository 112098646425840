import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
import Auth0 from './auth0';
import { ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import theme from './theme';
import store from './store'


const root = ReactDOM.createRoot(document.getElementById('root'));
const uriGrabber = () => {
  const {
    origin,
    href,
    search
  } = window.location
  if(/code/.test(search)) return 
  const path = href.replace(origin, '')
  // if(window.sessionStorage.getItem('loaded')) return
  window.localStorage.setItem('redirectPath', path)
}

uriGrabber()

root.render(
  <div
    style={{
      backgroundColor: 'rgba(84,106,123, 0.125)'
    }}
  >
    <CssBaseline />
    <Auth0>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </Auth0>
  </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
