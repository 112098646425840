import React from 'react'
import {useSelector, useDispatch} from 'react-redux';
import dispatchWrapper from '../../../utils/sdk/func-wrapper.js'
import api from './api.js'
import { apiClient } from '../../../utils/sdk/index.js'

function withEmployeeSafeGuards(Component) {
	return (props) => {

		const { employeeSafeguards, employee: { employmentType } } = useSelector(state => state.myEmployees)
		const loadingStatus = useSelector(state => state.myEmployees.employeeSafeguardsStatus)
		const dispatch = useDispatch()
		const employeeSDK = {
			...dispatchWrapper(dispatch, api(apiClient)),
			employeeSafeguards,
			employmentType,
			loadingStatus
		}
		const passThruProps = {...props, employeeSDK}

		return (
			<Component {...passThruProps} />
		)
	}
}

export default withEmployeeSafeGuards