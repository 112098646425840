import { createAsyncThunk } from '@reduxjs/toolkit'
import constants from '../redux/constants'

const api = client => ({
  findMyCompanySafeguards: () => {
    return async dispatch => {
      const fetchMySafeguards = createAsyncThunk(
        constants.FIND_MY_COMPANY_SAFEGUARDS,
        async () => {
          const response = await client.get(`/api/v2/my-company-safeguards`)
          return response.data
        }
      )
      dispatch(fetchMySafeguards())
    }
  },
  findAdvancesSummary: () => {
    return async dispatch => {
      const fetchAdvancesSummary = createAsyncThunk(
        constants.FIND_MY_COMPANY_ADVANCES_SUMMARY,
        async () => {
          const response = await client.get(`/api/v2/my-company-advances-summary`)
          return response.data
        }
      )
      dispatch(fetchAdvancesSummary())
    }
  },
  findLatestAdvances: () => {
    return async dispatch => {
      const fetchLatestAdvances = createAsyncThunk(
        constants.FIND_MY_EMPLOYEES_LATEST_ADVANCES,
        async () => {
          const response = await client.get(`/api/v2/my-ewa-employees/advances/latest`)
          return response.data
        }
      )
      dispatch(fetchLatestAdvances())
    }
  }
})

export default api