import defaultTheme from "./default";
import {deepmerge} from "@mui/utils";
import {createTheme} from '@mui/material/styles'
import oxyMaterialOverrides from './oxy-material-overrides'

const overrides = {
	typography: {
		fontFamily: [
			'Gabarito',
			'Arial',
			'sans-serif'
		].join(','),
		h6: {
			color: "var(--Primary-Navy, #546A7B)",
			fontWeight: 600,
			fontSize: 20,
			letterSpacing: 0.5,
			fontStyle: 'normal',
			lineHeight: '32px',
			margin: 0
		},
		h5: {
			color: "var(--Primary-Navy, #546A7B)",
			fontWeight: 600,
			fontSize: 24,
			letterSpacing: 0.5,
			fontStyle: 'normal',
			lineHeight: '32px',
			margin: 0
		},
		h4: {
			color: "var(--Primary-Navy, #546A7B)",
			textAlign: "center",

			/* Heading/H4 */
			fontFamily: "Gabarito",
			fontSize: "32px",
			fontStyle: "normal",
			fontWeight: "600",
			lineHeight: "40px" /* 125% */
		},
		p: {
			color: "var(--Primary-Navy, #546A7B)",
			fontSize: "16px",
			fontStyle: "normal",
			fontWeight: 400,
			lineHeight: "20px",
			margin: 0
		},
		text: {
			sm: {
				color: "var(--Primary-Navy, #546A7B)",
				fontFamily: "Gabarito",
				fontSize: "14px",
				fontStyle: "normal",
				fontWeight: "400",
			},
			md: {
				color: "var(--Primary-Navy, #546A7B)",
				fontFamily: "Gabarito",
				fontSize: "16px",
				fontStyle: "normal",
				fontWeight: "400",
				lineHeight: "20px", 
			}
		}
	},
	components: {
		...oxyMaterialOverrides
	},
	breakpoints: {
    values: {
      xs: 600,
      sm: 935,
      md: 1035,
      lg: 1200,
      xl: 1536,
    },
	}
}


export default createTheme(deepmerge(defaultTheme, overrides))