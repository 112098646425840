import React from 'react';
import styled from '@mui/material/styles/styled';

const StyledSplash = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  animation: 'fadeIn 2s',
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  },  
  '& img': { 
    height: '48px',
    width: '48px',
    animation: 'animate 2s linear infinite',
    '@keyframes animate': {
        '0%': {
          transform: 'rotateY(0deg)'
        },
        '100%': {
            transform: 'rotateY(360deg)'
        }
      }
    }  
}))

const Loading = () => {
  return (
    <StyledSplash>
      <img src="/heart-192-192.png" alt="loading" />
    </StyledSplash>
  )
}

export default Loading;